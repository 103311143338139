import client from './../api.js'

const endpoint = '/master/account-receivable'

export default {
  getPaginationAccountReceivable: (qs = null) => {
    return client.get(`${endpoint}${qs ? '?' + qs : ''}`)
  },
  getAccountReceivableById: (id) => {
    return client.get(`${endpoint}/${id}`)
  },
  createNewAccountReceivable: (body) => {
    return client.post(`${endpoint}`, body)
  },
  updateAccountReceivableById: (id, body) => {
    return client.patch(`${endpoint}/${id}`, body)
  },
  deleteAccountReceivableById: (id) => {
    return client.delete(`${endpoint}/${id}`)
  }
}
