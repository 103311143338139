<script>
import _ from 'lodash'

import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    accountReceivableProp: Object
  },
  components: { AppCard },
  data() {
    return {
      isLoading: false,
      acccountReceivable: {},
      accountReceivableDatas: [],
      firstAccountingDetailDatas: [],
      secondAccountingDetailDatas: [],
      billDetailDatas: [],
      generalDetailDatas: [],
      summaryTotalDebtDatas: []
    }
  },
  watch: {
    accountReceivableProp: {
      handler() {
        if (!_.isEqual(this.acccountReceivable, this.accountReceivableProp)) {
          this.acccountReceivable = _.cloneDeep(this.accountReceivableProp)
          this.initialize()
        }
      },
      deep: true
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.isLoading = true
      this.completeAccountReceivableDatas()
      this.completeFirstAccountingDetailDatas()
      this.completeSecondAccountingDetailDatas()
      this.completeBillDetailDatas()
      this.completeGeneralDetailDatas()
      this.completeSummaryTotalDebtDatas()
      this.isLoading = false
    },
    completeAccountReceivableDatas() {
      const {
        customerId,
        customerName,
        contact,
        address1,
        address2,
        province,
        zipCode,
        telephoneNumber,
        taxId
      } = this.acccountReceivable
      this.accountReceivableDatas = [
        {
          key: 'customerId',
          label: 'รหัสลูกค้า',
          value: customerId ?? '-'
        },
        {
          key: 'customerName',
          label: 'ชื่อลูกค้า',
          value: customerName ?? '-'
        },
        {
          key: 'contact',
          label: 'ติดต่อกับ',
          value: contact ?? '-'
        },
        {
          key: 'address1',
          label: 'ที่อยู่ 1 (เลขที่, ถนน)',
          value: address1 ?? '-'
        },
        {
          key: 'address2',
          label: 'ที่อยู่ 2 (ตำบล, อำเภอ)',
          value: address2 ?? '-'
        },
        {
          key: 'province',
          label: 'จังหวัด',
          value: province ?? '-'
        },
        {
          key: 'zipCode',
          label: 'รหัสไปรษณีย์',
          value: zipCode ?? '-'
        },
        {
          key: 'telephoneNumber',
          label: 'โทรศัพท์มือถือ',
          value: telephoneNumber ?? '-'
        },
        {
          key: 'taxId',
          label: 'รหัสประจำตัวผู้เสียภาษี',
          value: taxId ?? '-'
        }
      ]
    },
    completeFirstAccountingDetailDatas() {
      this.firstAccountingDetailDatas = [
        {
          key: 'customerCategory',
          label: 'ประเภทลูกค้า',
          value: '1: ลูกหนี้การค้าทั่วไป'
        },
        {
          key: 'debtType',
          label: 'เก็บประวัติหนี้แบบ',
          value: '1. เก็บรายละเอียด'
        },
        {
          key: 'creditTerm',
          label: 'ระยะเวลาให้เครดิต',
          value: '0 วัน'
        },
        {
          key: 'creditAmount',
          label: 'วงเงินให้เครดิต',
          value: '0 บาท'
        },
        {
          key: 'creditRemain',
          label: 'เครดิตคงเหลือ',
          value: '0 บาท'
        }
      ]
    },
    completeSecondAccountingDetailDatas() {
      this.secondAccountingDetailDatas = [
        {
          key: 'customerType',
          label: 'หมวดลูกค้า',
          value: '01 ลูกค้ากทม.'
        },
        {
          key: 'ifPaidIn',
          label: 'หากชำระภายใน',
          value: '0 วัน จะมีส่วนลด 0.00%'
        },
        {
          key: 'interest',
          label: 'ดอกเบี้ยเกินกำหนด',
          value: '0.00%'
        },
        {
          key: 'salePrice',
          label: 'ราคาขายเป็น',
          value: '100.00% ของราคาขายสินค้า [G]'
        },
        {
          key: 'firstLevelDiscount',
          label: 'ส่วนลดระดับที่ 1',
          value: '+10+5+ ในรายการสินค้า'
        }
      ]
    },
    completeBillDetailDatas() {
      this.billDetailDatas = [
        {
          key: 'billBeforeDeadline',
          label: 'วางบิลก่อนครบกำหนด',
          value: '0 วัน'
        },
        {
          key: 'billType',
          label: 'เปิดให้วางบิลตาม',
          value: 'DOM/DOW'
        }
      ]
    },
    completeGeneralDetailDatas() {
      this.generalDetailDatas = [
        {
          key: 'sellerId',
          label: 'รหัสพนักงานขาย',
          value: '001 คุณสมหวัง ขยันทำงาน'
        },
        {
          key: 'customerLevel',
          label: 'การจัดชั้นลูกค้า',
          value: '1'
        },
        {
          key: 'inventoryTransfer',
          label: 'มีโอนคลัง [Y/N]',
          value: 'N'
        },
        {
          key: 'from',
          label: 'จาก',
          value: '00 [ที่อยู่ 00]'
        },
        {
          key: 'to',
          label: 'ไป',
          value: '01 [ที่อยู่ 01]'
        },
        {
          key: 'currency',
          label: 'สกุลเงินที่ใช้',
          value: 'US$'
        }
      ]
    },
    completeSummaryTotalDebtDatas() {
      this.summaryTotalDebtDatas = [
        {
          key: 'previousDebt',
          label: 'หนี้คงค้างยกมา',
          value: '0 บาท'
        },
        {
          key: 'cumulativeDebt',
          label: 'หนี้สะสมทั้งปี',
          value: '0 บาท'
        },
        {
          key: 'yearlyPaid',
          label: 'ชำระแล้วทั้งปี',
          value: '0 บาท'
        },
        {
          key: 'remainDebt',
          label: 'หนี้คงค้างทั้งสิ้น',
          value: '0 บาท'
        },
        {
          key: 'latestSellDate',
          label: 'วันที่ขายล่าสุด',
          value: '0 บาท'
        },
        {
          key: 'latestSell',
          label: 'ขายครั้งล่าสุด',
          value: '0 บาท'
        },
        {
          key: 'latestReceive',
          label: 'รับเงินล่าสุด',
          value: '0 บาท'
        },
        {
          key: 'latestPaid',
          label: 'ยอดชำระล่าสุด',
          value: '0 บาท'
        }
      ]
    }
  }
}
</script>

<template>
  <app-card>
    <div v-if="isLoading" class="text-center my-2">
      <b-spinner class="align-middle mr-3" variant="primary"></b-spinner>
      <strong>กำลังโหลดข้อมูล ...</strong>
    </div>
    <div v-else>
      <!-- รายละเอียดข้อมูลลูกค้าลูกหนี้ -->
      <b-row>
        <b-col cols="12" class="mb-2">
          <span class="text-lg font-weight-bold">
            รายละเอียดข้อมูลลูกค้าลูกหนี้
          </span>
        </b-col>
        <b-col v-for="data in accountReceivableDatas" :key="data.key" cols="12">
          <div class="mb-1">
            <span class="mr-2 font-weight-bold">{{ data.label }} :</span>
            <span>
              {{ data.value }}
            </span>
          </div>
        </b-col>
      </b-row>

      <!-- รายละเอียดทางบัญชี 1 / รายละเอียดทางบัญชี 2 -->
      <b-row class="text-black-50">
        <b-col cols="12" md="6">
          <!-- รายละเอียดทางบัญชี 1 -->
          <b-row>
            <b-col cols="12" class="mt-5 mb-2">
              <span class="text-lg font-weight-bold">
                รายละเอียดทางบัญชี 1
              </span>
            </b-col>
            <b-col
              v-for="data in firstAccountingDetailDatas"
              :key="data.key"
              cols="12"
            >
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <!-- รายละเอียดทางบัญชี 2 -->
          <b-row>
            <b-col cols="12" class="mt-5 mb-2">
              <span class="text-lg font-weight-bold">
                รายละเอียดทางบัญชี 2
              </span>
            </b-col>
            <b-col
              v-for="data in secondAccountingDetailDatas"
              :key="data.key"
              cols="12"
            >
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- รายละเอียดการวางบิล / รายละเอียดอื่น ๆ ทั่วไป-->
      <b-row class="text-black-50">
        <b-col cols="12" md="6">
          <!-- รายละเอียดการวางบิล -->
          <b-row>
            <b-col cols="12" class="mt-5 mb-2">
              <span class="text-lg font-weight-bold">
                รายละเอียดการวางบิล
              </span>
            </b-col>
            <b-col cols="12" v-for="data in billDetailDatas" :key="data.key">
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <!-- รายละเอียดอื่น ๆ ทั่วไป -->
          <b-row>
            <b-col cols="12" class="mt-5 mb-2">
              <span class="text-lg font-weight-bold">
                รายละเอียดอื่น ๆ ทั่วไป
              </span>
            </b-col>
            <b-col cols="12" v-for="data in generalDetailDatas" :key="data.key">
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- สรุปยอดหนี้ลูกหนี้ -->
      <b-row class="text-black-50">
        <b-col cols="12" class="mt-5 mb-2">
          <span class="text-lg font-weight-bold"> สรุปยอดหนี้ลูกหนี้ </span>
        </b-col>
        <b-col cols="12" md="6">
          <!-- ฝั่งซ้าย -->
          <b-row>
            <b-col
              cols="12"
              v-for="data in summaryTotalDebtDatas.slice(0, 4)"
              :key="data.key"
            >
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <!-- ฝั่งขวา -->
          <b-row>
            <b-col
              cols="12"
              v-for="data in summaryTotalDebtDatas.slice(4)"
              :key="data.key"
            >
              <div class="mb-1">
                <span class="mr-2 font-weight-bold"> {{ data.label }} : </span>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </app-card>
</template>

<style></style>
