<script>
export default {}
</script>

<template>
  <b-row>
    <b-col cols="12" class="card-style bg-light p-4">
      <slot></slot>
    </b-col>
  </b-row>
</template>

<style scoped>
.card-style {
  border-radius: 10px;
}
</style>
