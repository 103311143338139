<script>
import accountReceivableService from '@/services/master/account-receivable'

export default {
  props: {
    accountReceivableProp: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getTargetAccountReceivableInfo() {
      if (this.accountReceivableProp) {
        const {
          customerId,
          customerCategoryId,
          customerName,
          contact,
          address,
          telephoneNumber,
          taxId
        } = this.accountReceivableProp
        return [
          { key: 'customerId', value: customerId || '-', label: 'รหัสลูกค้า' },
          {
            key: 'customerCategoryId',
            value: customerCategoryId || '-',
            label: 'ประเภท'
          },
          {
            key: 'customerName',
            value: customerName || '-',
            label: 'ชื่อลูกค้า'
          },
          { key: 'contact', value: contact || '-', label: 'ผู้รับการติดต่อ' },
          {
            key: 'address',
            value: `${address || '-'}`,
            label: 'ที่อยู่'
          },
          {
            key: 'telephoneNumber',
            value: telephoneNumber || '-',
            label: 'เบอร์โทรศัพท์'
          },
          {
            key: 'taxId',
            value: taxId || '-',
            label: 'หมายเลขประจำตัวผู้เสียภาษี'
          }
        ]
      }
      return []
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          if (this.accountReceivableProp) {
            this.deleteAccountReceivableById(this.accountReceivableProp.id)
          }
          break
        case 'CANCEL':
          this.$emit('closeModal', { type: 'CLOSE' })
          break
        default:
          break
      }
    },
    async deleteAccountReceivableById(id) {
      this.isLoading = true
      try {
        await accountReceivableService.deleteAccountReceivableById(id)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบลูกค้าลูกหนี้สำเร็จ'
        })
      } catch (err) {
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: err.response.data.thMessage
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div v-if="accountReceivableProp">
    <b-row align-v="start">
      <!-- trash icon -->
      <b-col class="col-3">
        <b-icon
          icon="trash-fill"
          variant="danger"
          class="mt-2 icon-style"
        ></b-icon>
      </b-col>

      <!-- content -->
      <b-col class="col-9">
        <h5>รายละเอียดของลูกค้าลูกหนี้ที่จะลบ</h5>
        <div
          class="d-flex mt-3"
          v-for="info in getTargetAccountReceivableInfo"
          :key="info.key"
        >
          <span> {{ info.label }}: </span>
          <span class="ml-2 font-weight-bold">
            {{ info.value }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr />

    <!-- buttons -->
    <b-row align-h="end">
      <b-col cols="3" lg="2">
        <b-button
          class="w-100"
          variant="danger"
          @click="buttonClicked('CONFIRM')"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>ลบ</span>
        </b-button>
      </b-col>
      <b-col cols="3" lg="2">
        <b-button
          class="w-100"
          variant="outline-danger"
          @click="buttonClicked('CANCEL')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>
